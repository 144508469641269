import React from "react";
import ReactDOM from "react-dom";
import Login from "srcDir/components/Login";
import fetch from "isomorphic-fetch";
import { notification } from "antd";
import Storage from "store2";
import { urlAppendQuery } from "./tools";
import ErrPage from "../pages/notFound/500"
import ErrPageTwo from "../pages/notFound/404"


function checkStatus(response) {
  // if (window.navigate) window.navigate.push("/500");
  const dvToken = response.headers.get("duplication_verify_token");
  if (dvToken) {
    Storage.set("dv_token", dvToken);
  }
  if (response.status >= 200 && response.status <= 400) {
    return response;
  }

  if (response.status === 401 || response.status === 403) {
    return response;
    // ReactDOM.render(<Login />, document.getElementById("root"));
  }
  if (response.status === 404) {
    ReactDOM.render(<ErrPageTwo />, document.getElementById("root"));
  }
  // if (response.status === 500) {
  //   ReactDOM.render(<ErrPage />, document.getElementById("root"));
  // }
  const error = new Error(response.statusText);
  error.response = response;
  throw error;
}

/**
 * Requests a URL, returning a promise.
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 * @return {object}           An object containing either "data" or "err"
 */

function send(url, options, autoTips, isLogin) {
  // if (url && !url.includes("http")) {
  //   const origin = isProduction ? config.host_prod : config.host_test;
  //   const api = (url = process.env.REACT_APP_API + url);
  // }
  // console.log("sss", url);
  const Authorization = Storage.get("AuthorizationBearer");
  const defaultOptions = {
    headers: !isLogin
      ? {
        Authorization: Authorization || "",
        requestsource: "platform"
      }
      : { requestsource: "platform" },
  };
  const newOptions = { ...defaultOptions, ...options };
  if (newOptions.method === "POST" || newOptions.method === "PUT") {
    newOptions.headers = {
      Accept: "application/json",
      // mode: "no-cors",
      "Content-Type": "application/json; charset=utf-8",
      ...newOptions.headers,
    };
    newOptions.body = JSON.stringify(newOptions.body);
  }
  if (newOptions.method === "UPLOAD") {
    newOptions.method = "POST";
  }
  // 拼接路径参数
  let newUrl = url;
  if (options && options.params) {
    newUrl = urlAppendQuery(url, {
      ...options.params,
    });
  }
  const checkBiz = (response) =>
    response.json().then((res) => {
      if (autoTips) {
        if (!res.success) {
          notification.error({
            message: res.msg,
          });
        } else if (res.msg) {
          notification.success({
            message: res.msg,
          });
        }
      }
      return res;
    });
  return fetch(newUrl, newOptions)
    .then(checkStatus)
    .then(checkBiz)
    .catch((error) => {
      return error;
    });
}
export default {
  GET(url, options, autoTips) {
    if (typeof options !== "object") {
      return send(url, { method: "GET" }, options);
    }
    return send(url, { ...options, method: "GET" }, autoTips);
  },
  POST(url, options, autoTips, isLogin) {
    if (typeof options !== "object") {
      return send(url, { method: "POST" }, options, isLogin);
    }
    return send(url, { ...options, method: "POST" }, autoTips, isLogin);
  },
  PUT(url, options, autoTips) {
    if (typeof options !== "object") {
      return send(url, { method: "PUT" }, options);
    }
    return send(url, { ...options, method: "PUT" }, autoTips);
  },
  UPLOAD(url, options, autoTips) {
    if (typeof options !== "object") {
      return send(url, { method: "UPLOAD" }, options);
    }
    return send(url, { ...options, method: "UPLOAD" }, autoTips);
  },
  DELETE(url, options, autoTips) {
    if (typeof options !== "object") {
      return send(url, { method: "DELETE" }, options);
    }
    return send(url, { ...options, method: "DELETE" }, autoTips);
  },
};
