// /////////////////////////////////////////////////////////////////
//                          _ooOoo_                               //
//                         o8888888o                              //
//                         88" . "88                              //
//                         (| ^_^ |)                              //
//                         O\  =  /O                              //
//                      ____/`---'\____                           //
//                    .'  \\|     |//  `.                         //
//                   /  \\|||  :  |||//  \                        //
//                  /  _||||| -:- |||||-  \                       //
//                  |   | \\\  -  /// |   |                       //
//                  | \_|  ''\---/''  |   |                       //
//                  \  .-\__  `-`  ___/-. /                       //
//                ___`. .'  /--.--\  `. . ___                     //
//              ."" '<  `.___\_<|>_/___.'  >'"".                  //
//            | | :  `- \`.;`\ _ /`;.`/ - ` : | |                 //
//            \  \ `-.   \_ __\ /__ _/   .-` /  /                 //
//      ========`-.____`-.___\_____/___.-`____.-'========         //
//                           `=---='                              //
//      ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^        //
//                  佛祖保佑       永不宕机     永无BUG              //
//                                                                //
// /////////////////////////////////////////////////////////////////

import React, { lazy, Suspense } from "react";
import ReactDOM from "react-dom";
import Storage from "store2";
import moment from "moment";
import "moment/locale/zh-cn";
import {
  Provider,
} from "react-keep-alive";
import { ConfigProvider, Spin } from 'antd';
import zhCN from "antd/es/locale/zh_CN";
import "./index.css";
import Login from "./components/Login";
import registerServiceWorker from "./registerServiceWorker";
import config from "projectRoot/config.json";

import { createRoot } from 'react-dom/client';
import './assets/icons/iconfont.css'; // 引入iconfont的CSS
moment.locale("zh-cn");
const Authorization = Storage.get("Authorization");
const container = document.getElementById('root');
const root = createRoot(container); // 如果你使用 TypeScript，请使用 createRoot(container!)
if (Authorization && Authorization !== "") {
  const PageComponent = lazy(() => import('./App'));

  root.render(
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#c69c6d',
          borderRadius: 4,
          colorLink: config["@primary-color"],
        },
        components: {
          Input: {
            controlHeight: 32,
            // activeBorderColor: '#c69c6d'
          },
          Button: {
            colorPrimary: config["@primary-color"],
            defaultColor: 'rgba(0, 0, 0, .65)',
            defaultBorderColor: '#d9d9d9',
          },
          Radio: {
            buttonColor: 'rgba(0, 0, 0, .65)'
          },
          Dropdown: {
            colorText: 'rgba(0, 0, 0, .65)'
          },
          Select: {
            colorText: 'rgba(0, 0 , 0)'
          },
          Breadcrumb: {
            lastItemColor: 'rgba(0, 0 , 0, .65)'
          },
          Table: {
            cellPaddingBlock: 10,
            cellPaddingInline: 10,
            // expandIconBg: '#c69c6d'
            rowHoverBg: '#fffaf0'
          },
          Menu: {
            itemColor: '#000',
            itemSelectedColor: '#c69c6d',
            itemMarginInline: 0
          },
          Checkbox: {
            borderRadiusSM: 2,
          }
        }
      }}
      locale={zhCN}
    >
      <Suspense fallback={
        <div style={{ height: "100%", background: config["@layout-body-background"] }}>
          <Spin spinning={true} tip="加载中，请等待...">
            < div style={{ height: '100vh', width: '100%' }}></div>
          </ Spin>
        </div>
      }>
        <Provider>
          <PageComponent />
        </Provider>
      </Suspense >
    </ConfigProvider>
  );

  // import("./App")
  //   .then((App) => {
  //     ReactDOM.render(
  //       <LocaleProvider locale={zhCN}>
  //         <Provider>
  //           <App.default />
  //         </Provider>
  //       </LocaleProvider>
  //       , document.getElementById("root"));
  //   })
  //   .catch((err) => {
  //     throw new Error(err);
  //   });
} else {
  root.render(

    <Login />
  );
}
registerServiceWorker();

