/* eslint-disable no-useless-concat */

import React, { Component } from "react";
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Form } from 'antd';
////import '@ant-design/compatible/assets/index.css';
import { Input, Button, Checkbox, message } from "antd";
import Request from "srcDir/util/request";
import { isProduction } from "srcDir/util/tools";
import config from "projectRoot/config.json";
import Storage from "store2";
// import BackImg from "srcDir/assets/images/home_page.jpg";
import logoPng from "srcDir/assets/images/logo-font.png";
import "./style.scss";

const FormItem = Form.Item;
console.log("isProduction", isProduction);


class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      logining: false,
    };
  }

  formRef = React.createRef()
  onFinish = (fields) => { //校验通过
    const origin = isProduction
      ? "https://a.ch3024.com/api"
      : "https://test.ch3024.com/api";
    this.setState({ logining: true });
    fields.vcode = "code";
    Request.POST(
      `${origin}/login?method=platform-username-password-login`,
      {
        body: fields,
      },
      false,
      true
    ).then((res) => {
      if (res.success) {
        const token = res.data.token.access_token;
        const tokenBearer = "Bearer " + res.data.token.access_token;
        Storage.set("Authorization", token);
        Storage.set("AuthorizationBearer", tokenBearer);
        window.location.reload();
      } else {
        message.error(res.msg || "登录失败,请检查网络");
        this.setState({ logining: false });
      }
    });

  }

  onFinishFailed = ({ errorFields }) => {
    //校验失败
    console.log('login-errorInfo', errorFields)
  }

  hasErrors = () => {
    const fieldsError = this.formRef.current?.getFieldsError?.()
    return fieldsError?.some(field => field?.errors.length > 0)
  }


  render() {

    const { logining } = this.state;
    return (
      <div
        style={{
          background: `url("//caihong-oss.oss-cn-shanghai.aliyuncs.com/public_file/images/platform/home_page.jpg") no-repeat center center`,
          backgroundSize: "cover",
        }}
        className="login-container"
      >
        <div className="login-wrapper">
          <div className="login-logo">
            <img src={logoPng} />
          </div>
          <div style={{ flex: 1 }}>
            <div className="login-box">
              <div className="login-box-title">彩虹之门管理后台</div>
              <Form
                onFinish={this.onFinish}
                onFinishFailed={this.onFinishFailed}
                initialValues={{ remember: false }}
                ref={this.formRef}
              >
                <FormItem name="username" rules={[{ required: true, message: "请输入账号" }]}>
                  <Input
                    size="large"
                    prefix={
                      <UserOutlined style={{ color: "rgba(0, 0, 0, .25)" }} />
                    }
                    placeholder="用户名/邮箱/手机号码"
                  />
                </FormItem>
                <FormItem name="password" rules={[{ required: true, message: "请输入密码" }]}>
                  <Input
                    size="large"
                    prefix={
                      <LockOutlined style={{ color: "rgba(0, 0, 0, .25)" }} />
                    }
                    type="password"
                    className="login-password"
                    placeholder="密码"
                  />
                </FormItem>
                <FormItem name="remember" valuePropName="checked">
                  <Checkbox >记住密码</Checkbox>
                  <Button
                    htmlType="submit"
                    className="login-btn"
                    type="primary"
                    loading={logining}
                    disabled={this.hasErrors()}
                  >
                    登录
                  </Button>
                </FormItem>
              </Form>
              <div className="login-box-footer">
                没有或忘记密码，请联系客服中心
              </div>
            </div>
          </div>
          <div className="login-footer">V 1.0</div>
        </div>
      </div>
    );
  }
}

export default index;
