
import React, { Component } from "react";
// import { Form } from 'antd';
////import '@ant-design/compatible/assets/index.css';
import { Button } from "antd";
import errPng from "srcDir/assets/images/500.png"
import config from "projectRoot/config.json";

let timer = null;
class ErrPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      count: 3
    };
  }
  componentDidMount() {
    timer = setInterval(() => {
      let count_tmp = this.state.count - 1;
      this.setState({
        count: count_tmp
      }, () => {
        if (this.state.count == 1) {
          this.goHome()
        }
      })
    }, 1000)
  }
  goHome = () => {
    clearInterval(timer)
    window.location.href = `/`
  }

  render() {
    return (
      <div style={{ display: "flex", alignItems: "center", height: "100%", justifyContent: "center" }}>
        <div style={{ display: "flex", flexFlow: "column", alignItems: "center" }}>
          <img src={errPng} style={{ width: 400, height: 300 }} />
          <div style={{ fontSize: 36, fontWeight: 500, marginTop: 32 }}>500</div>
          <div style={{ fontSize: 16, margin: "8px 0 32px" }}>对不起，你访问的页面出错了。</div>
          <Button type="primary" onClick={this.goHome}>返回首页({this.state.count}s)</Button>
        </div>
      </div>

    );
  }
}

export default ErrPage;
