/**
 * URL拼接字符串
 *
 * @param {any} url
 * @param {any} param
 * @returns {String} url
 */

import moment from "moment";
import config from "projectRoot/config.json";

console.log("config.host", config.host);
export function fixedZero(val) {
  return val * 1 < 10 ? `0${val}` : val;
}

export function urlAppendQuery(url, param) {
  // if (url && !url.includes("http")) {
  //   url = process.env.REACT_APP_API + url;
  // }
  if (!param) {
    return url;
  }
  let queryString = "";
  for (const key in param) {
    if ({}.hasOwnProperty.call(param, key)) {
      if (param[key] === false || param[key] === 0 || param[key]) {
        queryString += `&${key}=${encodeURIComponent(param[key])}`;
      }
    }
  }
  if (queryString) {
    return `${url}?${queryString.substring(1)}`;
  }
  return url;
}
/**
 * 保留两位小数（非负）
 * @param {*object} obj
 */
export function limitTowDecimals(value, precision = 2) {
  if (value) {
    precision = Number.isNaN(+precision) ? 2 : +precision;
    value = value.toString();
    value = value.replace(/[^\d.]/g, ""); // 清除"数字"和"."以外的字符
    value = value.replace(/^\./g, ""); // 验证第一个字符是数字
    value = value.replace(/\.{2,}/g, "."); // 只保留第一个, 清除多余的
    value = value.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");
    const reg = new RegExp(`^()*(\\d+)\\.(\\d{${precision}}).*$`);
    value = value.replace(reg, "$1$2.$3"); // 只能输入两个小数
    if (precision === 0) {
      value.replace(/\.+$/, "");
    }
  }
  return value;
}
/**
 * 格式化金额
 * @param {*object} obj
 */
export function moneyFormat(m) {
  let f = parseFloat(m);
  if (Number.isNaN(f)) {
    return false;
  }
  f = Math.round(m * 100) / 100;
  let s = f.toString();
  let rs = s.indexOf(".");
  if (rs < 0) {
    rs = s.length;
    s += ".";
  }
  while (s.length <= rs + 2) {
    s += "0";
  }
  return s;
}
/**
 * 时间参数分解
 * @param {*object} fields // 元数据
 * @param {String} formName // 原参数名
 * @param {String} startName // 现开始参数名
 * @param {String} endName //现结束参数名
 * @param {moment} dateFormat // YYYY-MM-DD HH:mm:ss
 * @returns
 */
export function timeResolve(
  fields,
  formName,
  startName,
  endName,
  dateFormat = "YYYY-MM-DD HH:mm:ss"
) {
  if (fields[formName] && fields[formName].length === 2) {
    fields[startName] = fields[formName][0].format(dateFormat);
    fields[endName] = fields[formName][1].format(dateFormat);
    delete fields[formName];
  } else {
    fields[startName] = "";
    fields[endName] = "";
  }
}
/**
 * 高性能去重
 *
 * @param {any} array
 * @param {any} key
 * @returns
 */
export function removeDuplicate(array, key) {
  if (key) {
    for (let i = 0; i < array.length; i += 1) {
      const pattern = array[i][key];
      for (let j = i + 1; j < array.length; j += 1) {
        const element = array[j];
        if (pattern === element[key]) {
          array.splice((j -= 1), 1);
        }
      }
    }
  } else {
    for (let i = 0; i < array.length; i += 1) {
      const pattern = array[i];
      for (let j = i + 1; j < array.length; j += 1) {
        const element = array[j];
        if (pattern === element) {
          array.splice((j -= 1), 1);
        }
      }
    }
  }
  return array;
}
/**
 * 深度遍历定位元素
 *
 * @param {{Array<String>}} array
 * @param {String} key
 * @param {Stirng} b
 * @param {Function} func
 * @returns {any}
 */
export function findPath(array, key, b, func, path = []) {
  for (let i = 0; i < array.length; i += 1) {
    const element = array[i];
    if (element.children && element.children.length) {
      path.push(element);
      if (element[key].toString() === b.toString()) {
        return func(path.concat());
      }
      findPath(element.children, key, b, func, path);
    } else if (element[key].toString() === b.toString()) {
      path.push(element);
      return func(path.concat());
    }
    if (i === array.length - 1) {
      path.pop();
    }
  }
}

export const isString = (obj) =>
  Object.prototype.toString.call(obj) === "[object String]";
export const isFunction = (obj) =>
  Object.prototype.toString.call(obj) === "[object Function]";

export function getMapValueByKeyString(obj, key) {
  const keys = key.split(".");
  if (keys.length < 2) {
    return obj[key];
  }
  return keys.reduce((a, b, i) => {
    if (i === 1) {
      const s = obj[a];
      if (s) {
        return s[b];
      }
    }
    if (a) {
      return a[b];
    }
    return a;
  });
}

export function getTimeDistance(type) {
  const now = new Date();
  now.setHours(0);
  now.setMinutes(0);
  now.setSeconds(0);
  const oneDay = 1000 * 60 * 60 * 24;

  if (type === "today") {
    return [moment(now), moment(now.getTime() + (oneDay - 1000))];
  }

  if (type === "yesterday") {
    now.setDate(now.getDate() - 1);
    return [moment(now), moment(now.getTime() + (oneDay - 1000))];
  }

  if (type === "week") {
    const beginTime = now.getTime() - 6 * oneDay;
    return [moment(beginTime), moment(now.getTime() + (oneDay - 1000))];
  }

  if (type === "half") {
    const beginTime = now.getTime() - 14 * oneDay;
    return [moment(beginTime), moment(now.getTime() + (oneDay - 1000))];
  }

  if (type === "month") {
    const beginTime = now.getTime() - 29 * oneDay;
    return [moment(beginTime), moment(now.getTime() + (oneDay - 1000))];
  }

  if (type === "year") {
    const year = now.getFullYear();
    return [moment(`${year}-01-01 00:00:00`), moment(`${year}-12-31 23:59:59`)];
  }
}

export const isProduction = location.origin.includes(config.origin);
// config.host === "http://web-api.ch3024.com/api" ||
// config.host === "https://web-api.ch3024.com/api";

export function debounce(func, delay = 200) {
  let timeoutId;

  return function () {
    const context = this;
    const args = arguments;

    clearTimeout(timeoutId);

    timeoutId = setTimeout(function () {
      func.apply(context, args);
    }, delay);
  };
}
